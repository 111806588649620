/*eslint-disable */

import {bus} from '@/main';
import countries from '@/services/country.json';
import mobileNumberUpdated from '../mobile-number-updated/index.vue';
import toast from '@/services/toast';

export default {
  name: 'update-user-details-form',
  components: {
    'mobile-number' : mobileNumberUpdated
  },
  props: ['selfUserInfo'],
  data () {
    return {
      date:'',
      submitted:false,
      countryList:[],
      country:'',
      city:'',
      address:'',
      mobileNoUpdatePopup:false,
      phone: {
        number: '',
        countryCode: ''
      },
      fullMobileCountryCode:'',
      minDatetime: new Date(),
      gender:'',
      mblUpdateData:null
    }
  },
  computed: {

  },
  mounted () {
    this.mblUpdateData = this.selfUserInfo
    this.date = this.selfUserInfo.dob || ''
    this.gender = this.selfUserInfo.gender || ''
    this.country = this.selfUserInfo.country || ''
    this.city = this.selfUserInfo.city || ''
    this.address = this.selfUserInfo.address || ''
    this.phone.countryCode = this.selfUserInfo.mobile_country_code
    this.fullMobileCountryCode = this.phone.countryCode + ' ' + this.selfUserInfo.mobile
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    bus.$on('mobileNoUpdatePopupBus', (data) => {
      this.mobileNoUpdatePopup = data
    });
  },
  methods: {
    updatedProfileNumber() {
      this.mobileNoUpdatePopup = true
      document.body.classList.add('popup_open_body')
    },
    getSelfUserInfoAgainFun(){
      this.$store.dispatch('getSelfUserInfoApiFun')
    },
    updateUserInfoFormBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
          country:this.country,
          city:this.city,
          address:this.address
          }
         this.$store.dispatch('selfUserInfoUpdateAction', data).then((res) => {
          toast.success(res.data.msg);
          this.getSelfUserInfoAgainFun()
          this.$router.push('/dashboard/');
          this.submitted =  false
         }).catch((err) => {
          toast.error(err.data.msg);
          this.submitted =  false
         })
         return;
        }
      })
    },
  }
}